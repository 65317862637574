import React from 'react';
import {
  Flex,
  // Image,
  Box,
  ButtonGroup,
  IconButton,
  // Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Badge,
  Text
} from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
// import { AiFillFilePdf } from 'react-icons/ai';
// import photo from '../assets/photo.jpg';

const About = () => {
  return <AboutHeader />;
};

const AboutHeader = () => (
  <Flex
    mx="auto"
    maxW="7xl"
    px={{ base: '4' }}
    flexDirection={{ base: 'column', lg: 'row' }}
    alignItems={{ base: 'center' }}>
    <ButtonGroup variant="unstyled" color="white" marginTop="5" spacing={16}>
      <IconButton
        as="a"
        href="https://github.com/mattbardal/"
        target="_blank"
        aria-label="GitHub"
        icon={<FaGithub fontSize="64px" />}
      />
      <IconButton
        as="a"
        href="https://www.linkedin.com/in/mattbardal/"
        target="_blank"
        aria-label="LinkedIn"
        icon={<FaLinkedin fontSize="64px" />}
      />
      <IconButton
        as="a"
        href="mailto:mattbardal@gmail.com"
        target="_blank"
        aria-label="Email"
        icon={<MdEmail fontSize="64px" />}
      />
      {/* <IconButton
            as="a"
            href="#"
            target="_blank"
            aria-label="CV"
            icon={<AiFillFilePdf fontSize="28px" />}
          /> */}
    </ButtonGroup>
  </Flex>
);

const WorkExperience = ({ company, title, time, text, tags }) => (
  <AccordionItem>
    <h2>
      <AccordionButton>
        <Box flex="1" textAlign="left">
          <span style={{ fontWeight: '700' }}>{company}</span> - {title}
        </Box>
        <Box mr="3">{time}</Box>
        <AccordionIcon />
      </AccordionButton>
    </h2>
    <AccordionPanel pb={4}>
      <Flex alignItems={{ base: 'center' }}>
        <Box>
          {text}
          <Text paddingTop="2">
            {tags.map((tag, index) => (
              <Badge
                key={index}
                variant="solid"
                backgroundColor="rgba(111,123,247,1)"
                borderRadius="5"
                p="1"
                mt="1"
                mr="2"
                fontSize="0.8em">
                {tag}
              </Badge>
            ))}
          </Text>
        </Box>
      </Flex>
    </AccordionPanel>
  </AccordionItem>
);
WorkExperience.propTypes = {
  company: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired
};

export default About;
